import { all, takeEvery, put, call } from 'redux-saga/effects';
import { localDataName } from './config';
import actions from './actions';
import authActions from '@iso/redux/auth/actions';
import siteConfig from '@iso/config/site.config';
import Notification from '@iso/components/Notification';
import moment from 'moment';

export const onActiveOrders = async () =>
  await fetch(`${siteConfig.apiUrl}/allorders`)
    .then((response) => response.json())
    .then((json) => {
      return json['orders'];
    });

export function* updateRestaurantOrdersSaga({ restaurantOrders, order }) {
  console.log('local storage restaurantOrders:', restaurantOrders);
  yield localStorage.setItem(localDataName, JSON.stringify(restaurantOrders));
  yield put({
    type: actions.UPDATE_RESTAURANT_ORDERS,
    restaurantOrders,
    order,
  });
}

function* fetchGetActiveOrders() {
  try {
    let activeOrders = yield call(onActiveOrders);
    yield put(actions.fetchGetActiveOrdersSuccess(activeOrders));
  } catch (error) {
    yield put(actions.fetchGetActiveOrdersFailure(error));
  }
}

export const onWidgetsData = async (restaurantId) =>
  await fetch(
    `${
      siteConfig.apiUrl
    }/widgetsData?restaurant_id=${restaurantId}&language=${localStorage.getItem(
      'selectLocale'
    )}`
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchGetWidgetsData({ payload }) {
  try {
    let widgetsData = yield call(onWidgetsData, payload);
    yield put(actions.fetchGetWidgetsDataSuccess(widgetsData));
  } catch (error) {
    yield put(actions.fetchGetWidgetsDataFailure(error));
  }
}

const sendRefundRequest = async (refund) => {
  // console.log('-----------------------siteDataRequest', refund);
  const comment = `${refund?.comment}`;
  const response = await fetch(
    `${siteConfig.apiUrl}/refund?order=` + refund.id,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        reason: refund.reason.toString(),
        price: `${refund.price.toFixed(2)}`,
        comment,
      }),
      crossDomain: true,
    }
  )
    .then((resp) => {
      console.log('resp------', resp);
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification(
        'error',
        'You got some error. Refund has not been completed'
      );
    });
  return response.json();
};

async function fetchAllDisputeData() {
  let res_id = localStorage.getItem('restaurantId');
  let response = await fetch(
    `https://hub.delivery/dispute/dispute/getall/${res_id}?auth=orderbyte_api_kocmleqxctrzl5`
  )
    .then((response) => response.json())
    .then((json) => {
      // console.log('get all dispute----', json);
      if (json.status === 0) {
        return json[''];
      } else {
        return json['data'];
      }
    })
    .catch((err) => {
      return json[''];
    });
  // console.log("get dispute response------", response);
  return response;
}

async function fetchDisputeHistoryData(disputeId) {
  let response = await fetch(
    `https://hub.delivery/dispute/dispute/history/${disputeId}?auth=orderbyte_api_kocmleqxctrzl5`
  )
    .then((response) => response.json())
    .then((json) => {
      return json['history'];
    })
    .catch((err) => {
      console.log('error-------', err);
    });
  return response;
}

async function fetchAllCrmData(data) {
  // console.log('crm data--------------------', data);

  let isFiltered = Object.entries(data).some(([key, value]) => {
    return (
      key !== 'resId' && value !== undefined && value !== '' && value !== null
    );
  });
  // let resId = localStorage.getItem('restaurantId');
  let requestData = {
    restaurant_id: parseInt(data.resId),
    offset: 0,
    limit: 5000,
    isFilter: isFiltered,
    filters: {
      no_of_purchases:
        data.number_of_purchases === undefined
          ? 0
          : Number(data.number_of_purchases),
      did_not_placed_order:
        data.last_Month === undefined ? 0 : Number(data.last_Month),
      amt_of_purchases:
        data.amount_of_purchases === undefined
          ? 0
          : Number(data.amount_of_purchases),
      avg_amt_of_purchases:
        data.avg_bill === undefined ? 0 : Number(data.avg_bill),
      last_purchase_date:
        data.date_start === undefined || data.date_start === null
          ? ''
          : moment(data.date_start).format('YYYY-MM-DD'),
      bdate:
        data.bday === undefined || data.bday === null
          ? ''
          : moment(data.bday).format('YYYY-MM-DD'),
      phone: data.phone === undefined ? '' : data.phone,
    },
  };
  // console.log('requestData', requestData);
  let response = await fetch(
    `https://orderbyte.io/api/crm/v1/customerlist_filter`,
    {
      method: 'POST',
      headers: {
        auth: 'order_xht4sAytlopr8hn_byte',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(requestData),
      crossDomain: true,
    }
  )
    .then((response) => response.json())
    .then((json) => {
      // console.log("get all crm----", json);
      if (json.status === 0) {
        return json.data;
      } else {
        return json.data;
      }
    })
    .catch((err) => {
      return json[''];
    });
  // console.log("get crm response------", response);
  return response;
}

function* fetchGetAllCRM({ payload }) {
  // console.log("payload", payload);
  try {
    // if (payload == undefined) {
    //   payload = null;
    // }
    let allCRM = yield call(fetchAllCrmData, payload);
    yield put(actions.fetchGetAllCrmSuccess(allCRM));
  } catch (error) {
    yield put(actions.fetchGetActiveOrdersFailure(error));
  }
}

function* fetchCrmFiltersStart() {
  try {
    let allCRM = yield call(fetchCrmFiltersStartData);
    yield put(actions.fetchGetAllCrmSuccess(allCRM));
  } catch (error) {
    yield put(actions.fetchGetActiveOrdersFailure(error));
  }
}

async function fetchAllBlogData() {
  let response = await fetch(
    `https://orderbyte.io/api/blog/v1/get?auth=b3JkZXJfYmxvZ19zZXJ2aWNlX2FwaV9ieXRl`
  )
    .then((response) => response.json())
    .then((json) => {
      // console.log('get all blog----', json);
      if (json.status === 0) {
        return json[''];
      } else {
        return json['data'];
      }
    })
    .catch((err) => {
      return json[''];
    });
  // console.log('get blog response------', response);
  return response;
}

async function fetchAllNewsData() {
  // console.log('fetch news called---------------------');
  let requestData = {
    offset: 0,
    limit: 0,
  };
  console.log('requestData', requestData);
  let response = await fetch(`https://orderbyte.io/api/news/v1/get`, {
    method: 'POST',
    headers: {
      auth: 'b3JkZXJfYmxvZ19zZXJ2aWNlX2FwaV9ieXRl',
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(requestData),
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((json) => {
      // console.log('get all news----', json);
      if (json.status === 0) {
        return json[''];
      } else {
        return json['data'];
      }
    })
    .catch((err) => {
      return json[''];
    });
  // console.log('get news response------', response);
  return response;
}

async function fetchAllPromoDiscountData(payloadIds) {
  // console.log('payloadIds', payloadIds);
  try {
    let response = await fetch(
      `https://orderbyte.io/api/pizzacoupon/v1/api/promo_codes?restaurant_id=${payloadIds.resId}&brand_id=${payloadIds.brandId}`
    );

    let json = await response.json(); // Await JSON parsing

    // console.log("get all PromoDiscount----", json);

    let result = json[''] || json; // Handle empty key case

    // console.log("get PromoDiscount------", result);

    return result;
  } catch (err) {
    console.error('Error fetching promo discounts:', err);
    return null; // Return null to avoid undefined behavior
  }
}

function* fetchAllPromoDiscount({ payload }) {
  try {
    let allPromoDiscount = yield call(fetchAllPromoDiscountData, payload);
    yield put(actions.fetchGetAllPromoDiscountSuccess(allPromoDiscount));
  } catch (error) {
    yield put(actions.fetchGetActiveOrdersFailure(error));
  }
}

function* fetchAllBlog() {
  try {
    let allBlog = yield call(fetchAllBlogData);
    yield put(actions.fetchGetAllBlogSuccess(allBlog));
  } catch (error) {
    yield put(actions.fetchGetActiveOrdersFailure(error));
  }
}

function* fetchAllNews() {
  try {
    let allNews = yield call(fetchAllNewsData);
    yield put(actions.fetchGetAllNewsSuccess(allNews));
  } catch (error) {
    yield put(actions.fetchGetActiveOrdersFailure(error));
  }
}

function* fetchGetAllDispute() {
  try {
    let allDispute = yield call(fetchAllDisputeData);
    yield put(actions.fetchGetAllDisputeSuccess(allDispute));
  } catch (error) {
    yield put(actions.fetchGetActiveOrdersFailure(error));
  }
}

function* fetchDisputeHistory({ payload }) {
  try {
    let allDisputeHistory = yield call(fetchDisputeHistoryData, payload);
    yield put(actions.fetchDisputeHistorySuccess(allDisputeHistory));
  } catch (error) {
    yield put(actions.fetchGetActiveOrdersFailure(error));
  }
}

const sendCreateDispute = async (dispute) => {
  var disputeData = JSON.stringify({
    order_id: dispute.order_id,
    order_date: dispute.order_date,
    resto_id: dispute.resto_id,
    resto_name: dispute.resto_name,
    customer_id: dispute.customer_id,
    customer_name: dispute.customer_name,
    net_amount: dispute.net_amount,
    disputed_amount: dispute.disputed_amount,
    deadline: dispute.deadline,
    dispute_with: dispute.dispute_with,
    dispute_type: dispute.dispute_type,
    dispute_desc: dispute.dispute_desc,
    reason: dispute.reason,
  });
  const response = await fetch(`https://hub.delivery/dispute/dispute/create`, {
    method: 'POST',
    headers: {
      auth: 'orderbyte_api_kocmleqxctrzl5',
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: disputeData,
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification(
        'error',
        'You got some error. Dispute has not been completed'
      );
    });

  if (response.status === 1) {
    Notification('success', 'Dispute has been Created Successfully');
  }
  return response.json();
};

const sendCreateReprint = async (orderID) => {
  // console.log("orderID saga-----",orderID);
  const response = await fetch(`${siteConfig.apiUrl}/reprint?id=` + orderID, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    // body: JSON.stringify({
    //   reason: refund.reason.toString(),
    //   price: `${refund.price.toFixed(2)}`,
    //   comment,
    // }),
    crossDomain: true,
  })
    .then((resp) => {
      console.log('resp------', resp);
      Notification('success', 'Reprint has been Created Successfully');
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification(
        'error',
        'You got some error. Reprint has not been completed'
      );
    });
  // return response.json();
};

const sendUpdateDisputeSuccess = async (updatedData) => {
  // console.log("updatedData",updatedData);
  var updateDisputeData = JSON.stringify({
    resto_id: updatedData.resto_id,
    customer_id: updatedData.customer_id,
    order_id: updatedData.order_id,
    status: updatedData.status,
    dispute_id: updatedData.dispute_id,
    title: updatedData.title,
    comments: updatedData.comments,
  });
  const response = await fetch(`https://hub.delivery/dispute/dispute/update`, {
    method: 'POST',
    headers: {
      auth: 'orderbyte_api_kocmleqxctrzl5',
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: updateDisputeData,
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification(
        'error',
        'You got some error. Dispute has not been completed'
      );
    });

  if (response.status === 1) {
    Notification('success', 'Dispute status has been Updated Successfully');
  }
  return response.json();
};

const sendSmsCrmSuccess = async (SMSData) => {
  // console.log('SMSData', SMSData);
  var updateSmsData = JSON.stringify(SMSData);
  const response = await fetch(`https://orderbyte.io/api/crm/v1/sms-campaign`, {
    method: 'POST',
    headers: {
      auth: 'order_xht4sAytlopr8hn_byte',
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: updateSmsData,
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification('error', 'You got some error. Sms has not been sent');
    });

  if (response.status === 1) {
    Notification('success', 'SMS Sent Successfully');
  }
  return response.json();
};

const sendEmailCrmSuccess = async (emailData) => {
  // console.log('emailData', emailData);
  var updateEmailData = JSON.stringify(emailData);
  const response = await fetch(`https://orderbyte.io/api/crm/v1/email-bulk`, {
    method: 'POST',
    headers: {
      auth: 'order_xht4sAytlopr8hn_byte',
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: updateEmailData,
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification('error', 'You got some error. Email has not been sent');
    });
  // console.log("response",response);

  if (response.status === 1) {
    Notification('success', 'Email Sent Successfully');
  } else {
    Notification('error', 'You got some error. Email has not been sent');
  }
  return response;
};

const sendPushCrmSuccess = async (pushData) => {
  console.log('pushData', pushData);
  // var updatePushData = JSON.stringify(pushData);
  // const response = await fetch(`https://orderbyte.io/api/crm/v1/sms`, {
  //   method: 'POST',
  //   headers: {
  //     auth: 'order_xht4sAytlopr8hn_byte',
  //     'Content-Type': 'application/json',
  //   },
  //   mode: 'cors',
  //   body: updatePushData,
  //   crossDomain: true,
  // })
  //   .then((response) => response.json())
  //   .then((json) => {
  //     return json;
  //   })
  //   .catch((err) => {
  //     console.log('error-------', err);
  //     Notification(
  //       'error',
  //       'You got some error. Push notification has not been sent'
  //     );
  //   });

  // if (response.status === 1) {
  //   Notification('success', 'Push notification Sent Successfully');
  // }
  // return response.json();
};

const sendCreateBlog = async (blog) => {
  console.log('saga data', blog);
  var myHeaders = new Headers();

  myHeaders.append('auth', 'b3JkZXJfYmxvZ19zZXJ2aWNlX2FwaV9ieXRl');

  var formData = new FormData();
  formData.append('blogFile', blog.image);
  formData.append('title', blog.title);
  formData.append('sub_title', blog.subTitle);
  formData.append('author_name', blog.author);
  formData.append('description', blog.summery);
  formData.append('sub_array', JSON.stringify(blog.table));
  formData.append('how_long', blog.howLong);
  formData.append('isPizzaBox', blog.isPizzaBox);

  for (var pair of formData.entries()) {
    console.log(pair[0] + ': ' + pair[1]);
  }

  console.log('formData---', formData);

  // var requestOptions = {
  //   method: "POST",
  //   headers: myHeaders,
  //   body: formdata,
  //   redirect: "follow",
  // };

  const response = await fetch(`https://orderbyte.io/api/blog/v1/create`, {
    method: 'POST',
    headers: myHeaders,
    body: formData,
    redirect: 'follow',
    mode: 'cors',
    // body: blogData,
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification('error', 'You got some error. Blog has not been completed');
    });

  if (response.status === 1) {
    Notification('success', 'Blog has been Created Successfully');
  }
  return response;
};

const sendEditBlogParticular = async (blog) => {
  console.log('saga data edit', blog);
  var myHeaders = new Headers();

  myHeaders.append('auth', 'b3JkZXJfYmxvZ19zZXJ2aWNlX2FwaV9ieXRl');

  var formData = new FormData();
  if (blog.image) {
    formData.append('blogFile', blog.image);
  }
  formData.append('title', blog.title);
  formData.append('sub_title', blog.subTitle);
  formData.append('author_name', blog.author);
  formData.append('description', blog.summery);
  formData.append('sub_array', JSON.stringify(blog.table));
  formData.append('how_long', blog.howLong);
  formData.append('blog_id', blog.blog_id);

  // for (var pair of formData.entries()) {
  //   console.log(pair[0] + ": " + pair[1]);
  // }

  // console.log("formData---", formData);

  const response = await fetch(`https://orderbyte.io/api/blog/v1/update`, {
    method: 'PUT',
    headers: myHeaders,
    body: formData,
    redirect: 'follow',
    mode: 'cors',
    // body: blogData,
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification('error', 'You got some error. Blog has not been Edited');
    });

  if (response.status === 1) {
    Notification('success', 'Blog has been Edited Successfully');
  }
  return response.json();
};

const deleteByBlog = async (blogId) => {
  console.log('saga data delete blog', blogId);
  let data = {
    blog_id: blogId,
  };
  var deleteBlogData = JSON.stringify(data);
  const response = await fetch(`https://orderbyte.io/api/blog/v1/delete`, {
    method: 'DELETE',
    headers: {
      auth: 'b3JkZXJfYmxvZ19zZXJ2aWNlX2FwaV9ieXRl',
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: deleteBlogData,
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification('error', 'You got some error. Blog has not been deleted');
    });

  if (response.status === 1) {
    Notification('success', 'Delete blog Successfully');
  }
  return response.json();
};

const sendCreateNews = async (news) => {
  // console.log('saga data', news);
  var myHeaders = new Headers();

  myHeaders.append('auth', 'b3JkZXJfYmxvZ19zZXJ2aWNlX2FwaV9ieXRl');

  var formData = new FormData();
  formData.append('title', news.title);
  formData.append('author_name', news.author);
  formData.append('description', 'NA');
  formData.append('date', news.date);
  formData.append('link', news.summery);
  formData.append('isPizzaBox', news.isPizzaBox);

  for (var pair of formData.entries()) {
    console.log(pair[0] + ': ' + pair[1]);
  }

  console.log('formData---', formData);

  // var requestOptions = {
  //   method: "POST",
  //   headers: myHeaders,
  //   body: formdata,
  //   redirect: "follow",
  // };

  const response = await fetch(`https://orderbyte.io/api/news/v1/create`, {
    method: 'POST',
    headers: myHeaders,
    body: formData,
    redirect: 'follow',
    mode: 'cors',
    // body: blogData,
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification('error', 'You got some error. News has not been completed');
    });

  if (response.status === 1) {
    Notification('success', 'News has been Created Successfully');
  }
  return response;
};

const sendCreatePromoDiscount = async (data) => {
  // console.log('saga data', data);

  const response = await fetch(
    `https://orderbyte.io/api/pizzacoupon/v1/api/promo_codes`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      mode: 'cors',
      body: JSON.stringify(data),
      crossDomain: true,
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification(
        'error',
        'You got some error. Promo discount has not been completed'
      );
    });

  if (response) {
    Notification('success', 'Promo discount has been Created Successfully');
  }
  return response;
};

const sendEditPromoDiscountParticular = async (data) => {
  // console.log("saga data edit", data);

  const response = await fetch(
    `https://orderbyte.io/api/pizzacoupon/v1/api/promo_codes_update`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      redirect: 'follow',
      mode: 'cors',
      crossDomain: true,
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification(
        'error',
        'You got some error. Promo discount has not been Edited'
      );
    });

  if (response) {
    Notification('success', 'Promo discount has been Edited Successfully');
  }
  return response.json();
};

const deleteByPromoDiscount = async (data) => {
  // console.log('saga data delete', data);

  var deleteData = JSON.stringify(data);
  const response = await fetch(
    `https://orderbyte.io/api/pizzacoupon/v1/api/promo_codes`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: deleteData,
      crossDomain: true,
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification(
        'error',
        'You got some error. Promo discount has not been deleted'
      );
    });

  if (response) {
    Notification('success', 'Delete Promo discount Successfully');
  }
  return response.json();
};

const sendEditNewsParticular = async (news) => {
  // console.log("saga data edit", news);
  var myHeaders = new Headers();

  myHeaders.append('auth', 'b3JkZXJfYmxvZ19zZXJ2aWNlX2FwaV9ieXRl');

  var formData = new FormData();
  formData.append('title', news.title);
  formData.append('author_name', news.author);
  formData.append('description', 'NA');
  formData.append('date', news.date);
  formData.append('link', news.summery);
  formData.append('news_id', news.news_id);

  for (var pair of formData.entries()) {
    console.log(pair[0] + ': ' + pair[1]);
  }

  console.log('formData---', formData);

  const response = await fetch(`https://orderbyte.io/api/news/v1/update`, {
    method: 'PUT',
    headers: myHeaders,
    body: formData,
    redirect: 'follow',
    mode: 'cors',
    // body: blogData,
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification('error', 'You got some error. News has not been Edited');
    });

  if (response.status === 1) {
    Notification('success', 'News has been Edited Successfully');
  }
  return response.json();
};

const deleteByNews = async (newsId) => {
  // console.log('saga data delete newsId', newsId);
  let data = {
    news_id: newsId,
  };
  var deleteBlogData = JSON.stringify(data);
  const response = await fetch(`https://orderbyte.io/api/news/v1/delete`, {
    method: 'DELETE',
    headers: {
      auth: 'b3JkZXJfYmxvZ19zZXJ2aWNlX2FwaV9ieXRl',
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: deleteBlogData,
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log('error-------', err);
      Notification('error', 'You got some error. News has not been deleted');
    });

  if (response.status === 1) {
    Notification('success', 'Delete News Successfully');
  }
  return response.json();
};

function* sendRefund(action) {
  const data = action.payload.data;

  const siteDataRequest = {
    ...action.payload.data,
    id: action.payload.data.id,
  };
  let siteData = yield call(sendRefundRequest, siteDataRequest);

  yield put(actions.setRefundedId(action.payload.data.id));
}

function* sendBlog(action) {
  const data = action.payload;
  // console.log("data send----", data);
  let siteData = yield call(sendCreateBlog, data);

  // yield put(actions.setRefundedId(action.payload.data.id));
}

function* sendEditBlog(action) {
  const data = action.payload;
  // console.log("data send----", data);
  let siteData = yield call(sendEditBlogParticular, data);

  // yield put(actions.setRefundedId(action.payload.data.id));
}

function* deleteBlog(action) {
  const data = action.payload;
  // console.log("data send----", data);
  let siteData = yield call(deleteByBlog, data);

  // yield put(actions.setRefundedId(action.payload.data.id));
}

function* sendNews(action) {
  const data = action.payload;
  // console.log("data send----", data);
  let siteData = yield call(sendCreateNews, data);

  // yield put(actions.setRefundedId(action.payload.data.id));
}

function* sendPromoDiscount(action) {
  const data = action.payload;
  // console.log("data send----", data);
  let siteData = yield call(sendCreatePromoDiscount, data);

  // yield put(actions.setRefundedId(action.payload.data.id));
}

function* sendEditNews(action) {
  const data = action.payload;
  // console.log("data send----", data);
  let siteData = yield call(sendEditNewsParticular, data);

  // yield put(actions.setRefundedId(action.payload.data.id));
}

function* sendEditPromoDiscount(action) {
  const data = action.payload;
  // console.log("data send----", data);
  let siteData = yield call(sendEditPromoDiscountParticular, data);

  // yield put(actions.setRefundedId(action.payload.data.id));
}

function* deleteNews(action) {
  const data = action.payload;
  // console.log("data send----", data);
  let siteData = yield call(deleteByNews, data);

  // yield put(actions.setRefundedId(action.payload.data.id));
}

function* deletePromoDiscount(action) {
  const data = action.payload;
  // console.log("data send----", data);
  let siteData = yield call(deleteByPromoDiscount, data);

  // yield put(actions.setRefundedId(action.payload.data.id));
}

function* sendDispute(action) {
  const data = action.payload;
  // console.log("data send----", data);
  let siteData = yield call(sendCreateDispute, data);

  // yield put(actions.setRefundedId(action.payload.data.id));
}
function* sendReprint(action) {
  const data = action.payload;
  // console.log("data send----", data);
  let siteData = yield call(sendCreateReprint, data);

  // yield put(actions.setRefundedId(action.payload.data.id));
}
function* sendUpdateDispute(action) {
  const updatedData = action.payload;
  // console.log("data send----", data);
  try {
    let siteData = yield call(sendUpdateDisputeSuccess, updatedData);
  } catch (error) {
    console.log(error);
  }

  // yield put(actions.setRefundedId(action.payload.data.id));
}

function* sendSmsCrm(action) {
  const SMSData = action.payload;
  // console.log("data send----", data);
  try {
    let siteData = yield call(sendSmsCrmSuccess, SMSData);
  } catch (error) {
    console.log(error);
  }

  // yield put(actions.setRefundedId(action.payload.data.id));
}

function* sendEmailCrm(action) {
  const emailData = action.payload;
  // console.log("data send----", data);
  try {
    let siteData = yield call(sendEmailCrmSuccess, emailData);
  } catch (error) {
    console.log(error);
  }

  // yield put(actions.setRefundedId(action.payload.data.id));
}

function* sendPushCrm(action) {
  const pushData = action.payload;
  // console.log("data send----", data);
  try {
    let siteData = yield call(sendPushCrmSuccess, pushData);
  } catch (error) {
    console.log(error);
  }

  // yield put(actions.setRefundedId(action.payload.data.id));
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(
      actions.UPDATE_RESTAURANT_ORDERS_SAGA,
      updateRestaurantOrdersSaga
    ),
    yield takeEvery(
      actions.FETCH_GET_ACTIVE_ORDERS_START,
      fetchGetActiveOrders
    ),
    yield takeEvery(actions.FETCH_GET_WIDGETS_DATA_START, fetchGetWidgetsData),
    yield takeEvery(actions.SEND_REFUND_REQUEST__START, sendRefund),
    yield takeEvery(actions.CREATE_DISPUTE, sendDispute),
    yield takeEvery(actions.CREATE_REPRINT, sendReprint),
    yield takeEvery(actions.FETCH_GET_ALL_DISPUTE, fetchGetAllDispute),
    yield takeEvery(actions.UPDATE_DISPUTE, sendUpdateDispute),
    yield takeEvery(actions.FETCH_DISPUTE_HISTORY, fetchDisputeHistory),
    yield takeEvery(actions.FETCH_GET_ALL_BLOG, fetchAllBlog),
    yield takeEvery(actions.CREATE_BLOG, sendBlog),
    yield takeEvery(actions.EDIT_BLOG, sendEditBlog),
    yield takeEvery(actions.DELETE_BLOG, deleteBlog),
    yield takeEvery(actions.FETCH_GET_ALL_NEWS, fetchAllNews),
    yield takeEvery(actions.CREATE_NEWS, sendNews),
    yield takeEvery(actions.EDIT_NEWS, sendEditNews),
    yield takeEvery(actions.DELETE_NEWS, deleteNews),
    yield takeEvery(
      actions.FETCH_GET_ALL_PROMO_DISCOUNT,
      fetchAllPromoDiscount
    ),
    yield takeEvery(actions.CREATE_PROMO_DISCOUNT, sendPromoDiscount),
    yield takeEvery(actions.EDIT_PROMO_DISCOUNT, sendEditPromoDiscount),
    yield takeEvery(actions.DELETE_PROMO_DISCOUNT, deletePromoDiscount),
    yield takeEvery(actions.FETCH_GET_ALL_CRM, fetchGetAllCRM),
    yield takeEvery(actions.FETCH_CRM_FILTER_START, fetchCrmFiltersStart),
    yield takeEvery(actions.ADD_SEND_SMS, sendSmsCrm),
    yield takeEvery(actions.ADD_SEND_EMAIL, sendEmailCrm),
    yield takeEvery(actions.ADD_SEND_PUSH, sendPushCrm),
  ]);
}

export function* logout() {
  yield takeEvery(authActions.LOGOUT, function* () {
    yield put({
      type: actions.LOGOUT,
    });
  });
}
