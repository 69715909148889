import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import siteConfig from '@iso/config/site.config';
import Notification from '@iso/components/Notification';

const onRKeeperSettingsRequest = async (userId) => {
  const response = await fetch(`${siteConfig.apiUrl}/rkStationData/${userId}`);
  return response.json();
};

function* fetchRKeeperSettings(action) {
  try {
    let rKeeperSettings = yield call(onRKeeperSettingsRequest, action.payload);
    yield put(
      actions.fetchRKeeperSettingsSuccess(action.payload, rKeeperSettings)
    );
  } catch (error) {
    yield put(actions.fetchRKeeperSettingsFailure(error));
  }
}

const submitRKeeperSettingsRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/rkStationData/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* submitRKeeperSettings(action) {
  try {
    let res = yield call(submitRKeeperSettingsRequest, action.payload);
    yield put(actions.submitRKeeperSettingsSuccess(action.payload.userId, res));
  } catch (error) {
    yield put(actions.submitRKeeperSettingsFailure(error));
  }
}

const createRKeeperSettingsRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/rkStationData`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* createRKeeperSettings(action) {
  try {
    let res = yield call(createRKeeperSettingsRequest, action.payload);
    yield put(actions.submitRKeeperSettingsSuccess(action.payload.userId, res));
  } catch (error) {
    yield put(actions.submitRKeeperSettingsFailure(error));
  }
}

const submitIikoSettingsRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/iikoData/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* submitIikoSettings(action) {
  try {
    let res = yield call(submitIikoSettingsRequest, action.payload);
    yield put(actions.submitIikoSettingsSuccess(action.payload.userId, res));
  } catch (error) {
    yield put(actions.submitIikoSettingsFailure(error));
  }
}

const createIikoSettingsRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/iikoData`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* createIikoSettings(action) {
  try {
    let res = yield call(createIikoSettingsRequest, action.payload);
    yield put(actions.submitIikoSettingsSuccess(action.payload.userId, res));
  } catch (error) {
    yield put(actions.submitIikoSettingsFailure(error));
  }
}

async function fetchPartnerSettingsRequest(userId) {
  const response = await fetch(
    `${siteConfig.apiUrl}/partnerSettings/${userId}`
  );
  return response.json();
}

function* fetchPartnerSettings(action) {
  try {
    const partnerSettings = yield call(
      fetchPartnerSettingsRequest,
      action.payload
    );
    yield put(
      actions.fetchPartnerSettingsSuccess(
        action.payload.userId,
        partnerSettings
      )
    );
  } catch (error) {
    yield put(actions.fetchPartnerSettingsFailure(error));
  }
}

async function fetchAutomationSettingsRequest(restaurantId) {
  const response = await fetch(
    `${siteConfig.apiUrl}/automation?rest=${restaurantId}`
  );
  return response.json();
}

function* fetchAutomationSettings(action) {
  try {
    const automationSettings = yield call(
      fetchAutomationSettingsRequest,
      action.payload.restaurantId
    );
    yield put(
      actions.fetchAutomationSettingsSuccess(
        action.payload.restaurantId,
        automationSettings
      )
    );
  } catch (error) {
    yield put(actions.fetchAutomationSettingsFailure(error));
  }
}

const submitAutomationSettingsRequest = async (automationSettings) => {
  console.log('1111111111111111111111111', automationSettings);
  const response = await fetch(
    `${siteConfig.apiUrl}/automation?rest=` + automationSettings.restaurantId,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(automationSettings),
      crossDomain: true,
    }
  );
  return response.json();
};

function* submitAutomationSettings(action) {
  try {
    const data = action.payload.data;
    console.log('****************data', action.payload.restaurantId);

    const siteDataRequest = {
      ...action.payload.data,
      restaurantId: action.payload.restaurantId,
    };

    console.log('-----------------------siteDataRequest', siteDataRequest);
    let siteData = yield call(submitAutomationSettingsRequest, siteDataRequest);

    yield put(actions.submitAutomationSettingsSuccess(action.payload.data));
  } catch (error) {
    yield put(actions.submitAutomationSettingsFailure(error));
  }
}

const submitPartnerSettingsRequest = async (partnerSettings) => {
  const response = await fetch(`${siteConfig.apiUrl}/partnerSettings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(partnerSettings),
    crossDomain: true,
  });
  return response.json();
};

function* submitPartnerSettings(action) {
  try {
    const data = action.payload.data;
    console.log('--------------------------data', data);
    const userId = data.brand_id;

    const settingsDataRequest = {
      ...data,
      id: userId,
    };
    console.log('settingsDataRequest', settingsDataRequest);
    yield call(submitPartnerSettingsRequest, settingsDataRequest);

    const siteDataRequest = {
      id: userId,
      user_id: userId,
      brand_id: 1,
      restaurant_id: data.restaurant_id,
      restaurant_lat: data.restaurant_address_yandex?.lat
        .toString()
        .slice(0, 14),
      restaurant_lon: data.restaurant_address_yandex?.long
        .toString()
        .slice(0, 14),
      restaurant_address: data.restaurant_address_yandex?.addressLine.replace(
        "'",
        ''
      ),
      courier_phone_number:
        (data.courier_phone_number.indexOf('+') === -1 ? '+' : '') +
        data.courier_phone_number,
      buyer_phone_number:
        (data.buyer_phone_number.indexOf('+') === -1 ? '+' : '') +
        data.buyer_phone_number,
      buyer_phone_number_whatsapp:
        (data.buyer_phone_number_whatsapp.indexOf('+') === -1 ? '+' : '') +
        data.buyer_phone_number_whatsapp,
      mail_for_incoming_orders: data.mail_for_incoming_orders,
      legal_entity_of_restaurant: data.legal_entity_of_restaurant,
      inn: data.inn,
      ogrn: data.ogrn,
      bank: data.bank,
      city: data.city,
      restaurant_chain: data.restaurant_chain,
      checking_account: data.checking_account,
      restaurant_name: data.restaurantName.replace("'", "''"),
      company_name: data.company_name,
      comments_how_to_find_restaurant: data.comments_how_to_find_restaurant,
      token: data.token,
      available_delivery_method: data.available_delivery_method,
      serviceId: data.serviceId,
      business_type: data.business_type,
      date_of_birth: data.date_of_birth,
      company_address: data.company_address.addressLine,
      company_phone: data.company_phone,
      company_mail: data.company_mail,
      accept_auto: data.accept_auto,
      time_zone: data.time_zone,
      email: data.email,
    };

    // console.log('-----------------------siteDataRequest', siteDataRequest);
    let siteData = yield call(submitCreateSiteRequest, siteDataRequest);

    yield put(
      actions.submitPartnerSettingsSuccess(action.payload.partnerSettings)
    );
  } catch (error) {
    yield put(actions.submitPartnerSettingsFailure(error));
  }
}

const submitPartnerPlanRequest = async (tariffPlanData) => {
  const response = await fetch(`${siteConfig.apiUrl}/partnerTariff`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(tariffPlanData),
    crossDomain: true,
  });
  return response.json();
};

const submitLearningStepRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/partnerLearning`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* submitPartnerPlan(action) {
  try {
    let settings = yield call(submitPartnerPlanRequest, {
      id: action.payload.userId,
      ...action.payload.data,
    });
    const stepData = action.payload.stepData;
    if (stepData.save) {
      settings = yield call(submitLearningStepRequest, {
        id: action.payload.userId,
        learningStep: stepData.step,
      });
    }
    yield put(
      actions.submitPartnerPlanSuccess(action.payload.userId, settings)
    );
    action.payload.history?.push(`/intro/${stepData.step}`);
    // history.push();
  } catch (error) {
    yield put(actions.submitPartnerSettingsFailure(error));
  }
}

async function fetchBrandSettingsRequest(userId, restaurantId) {
  const response = await fetch(
    `${siteConfig.apiUrl}/wlBrandSettings?user_id=${userId}&restaurant_id=${restaurantId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token'),
      },
      mode: 'cors',
      crossDomain: true,
    }
  );
  return response.json();
}

function* fetchBrandSettingsStart(action) {
  try {
    const { userId, restaurantId } = action.payload;
    let settings = yield call(fetchBrandSettingsRequest, userId, restaurantId);

    yield put(actions.fetchBrandSettingsSuccess(userId, settings));
  } catch (error) {
    yield put(actions.submitPartnerSettingsFailure(error));
  }
}

async function submitPartnerInialsRequest(initialsData) {
  const response = await fetch(`${siteConfig.apiUrl}/partnerInitials`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(initialsData),
    crossDomain: true,
  });
  return response.json();
}

async function submitCreateSiteRequest(createSiteData) {
  const response = await fetch(`${siteConfig.apiUrl}/wlBrandSettings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(createSiteData),
    crossDomain: true,
  });
  return response.json();
}

export const onCreateWL = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/createwl`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

export const onSiteDomainWL = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/wlSiteDomain`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* submitCreateSite(action) {
  try {
    const userId = action.payload.userId;
    const data = action.payload.data;

    const initialsData = {
      id: userId,
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
    };
    let settings = yield call(submitPartnerInialsRequest, initialsData);

    const siteDataRequest = {
      user_id: userId,
      brand_id: 1,
      restaurant_id: data.restaurant_id,
      restaurant_lat: data.restaurant_address_yandex?.lat
        .toString()
        .slice(0, 14),
      restaurant_lon: data.restaurant_address_yandex?.long
        .toString()
        .slice(0, 14),
      restaurant_address: data.restaurant_address_yandex?.addressLine,
      courier_phone_number:
        (data.courier_phone_number.indexOf('+') === -1 ? '+' : '') +
        data.courier_phone_number,
      buyer_phone_number:
        (data.buyer_phone_number.indexOf('+') === -1 ? '+' : '') +
        data.buyer_phone_number,
      buyer_phone_number_whatsapp:
        (data.buyer_phone_number_whatsapp.indexOf('+') === -1 ? '+' : '') +
        data.buyer_phone_number_whatsapp,
      mail_for_incoming_orders: data.mail_for_incoming_orders,
      legal_entity_of_restaurant: data.legal_entity_of_restaurant,
      inn: data.inn,
      ogrn: data.ogrn,
      bank: data.bank,
      city: data.city,
      checking_account: data.checking_account,
      restaurant_name: data.restaurant_name,
      company_name: data.company_name,
      comments_how_to_find_restaurant: data.comments_how_to_find_restaurant,
      token: data.token,
      available_delivery_method: data.available_delivery_method,
      serviceId: data.serviceId,
    };
    let siteData = yield call(submitCreateSiteRequest, siteDataRequest);

    const stepData = action.payload.stepData;
    if (stepData.save) {
      settings = yield call(submitLearningStepRequest, {
        id: userId,
        learningStep: stepData.step,
      });
    }
    yield put(actions.submitCreateSiteSuccess(userId, settings));

    action.payload.history?.push(`/intro/${stepData.step}`);
    // history.push(`/intro/${stepData.step}`);
  } catch (error) {
    yield put(actions.submitCreateSiteFailure(error));
  }
}

function* submitCreateMenu(action) {
  try {
    const userId = action.payload.userId;
    const data = action.payload.data;

    const stepData = action.payload.stepData;
    let settings = {
      id: userId,
      learningStep: stepData.step,
    };
    if (stepData.save) {
      settings = yield call(submitLearningStepRequest, {
        id: userId,
        learningStep: stepData.step,
      });
    }
    yield put(actions.submitCreateMenuSuccess(userId, settings));

    action.payload.history?.push(`/intro/${stepData.step}`);

    // history.push(`/intro/${stepData.step}`);
  } catch (error) {
    yield put(actions.submitCreateMenuFailure(error));
  }
}

function* submitFillBrand(action) {
  try {
    const userId = action.payload.userId;

    const stepData = action.payload.stepData;
    let settings = {
      id: userId,
      learningStep: stepData.step,
    };
    if (stepData.save) {
      settings = yield call(submitLearningStepRequest, {
        id: userId,
        learningStep: stepData.step,
      });
    }
    yield put(actions.submitFillBrandSuccess(userId, settings));

    action.payload.history?.push(`/intro/${stepData.step}`);
  } catch (error) {
    yield put(actions.submitCreateMenuFailure(error));
  }
}

function* submitSiteAddress(action) {
  try {
    const userId = action.payload.userId;
    const stepData = action.payload.stepData;
    const wlData = action.payload.wlData;

    let settings = {
      id: userId,
      learningStep: stepData.step,
    };
    if (stepData.save) {
      settings = yield call(submitLearningStepRequest, {
        id: userId,
        learningStep: stepData.step,
      });
    }
    yield call(onCreateWL, wlData);

    yield put(actions.submitSiteAddressSuccess(userId, settings));

    console.log(action);
    action.payload.history?.push(`/intro/${stepData.step}`);
  } catch (error) {
    yield put(actions.submitCreateMenuFailure(error));
  }
}

function* submitSiteAddressDomain(action) {
  try {
    const wlData = action.payload.wlData;

    yield call(onSiteDomainWL, wlData);
    yield put(actions.submitSiteAddressDomainSuccess(wlData.userId, wlData));
  } catch (error) {
    yield put(actions.submitCreateMenuFailure(error));
  }
}

const onIikoSettingsRequest = async (restaurantId) => {
  const response = await fetch(
    `${siteConfig.apiUrl}/iikoData?restaurant_id=${restaurantId}`
  );
  return response.json();
};

function* fetchIikoSettings(action) {
  try {
    let iikoSettings = yield call(onIikoSettingsRequest, action.payload);
    yield put(actions.fetchIikoSettingsSuccess(action.payload, iikoSettings));
  } catch (error) {
    yield put(actions.fetchIikoSettingsFailure(error));
  }
}

const onPOSIntegrationRequest = async (restaurantId) => {
  const response = await fetch(
    `${siteConfig.apiUrl}/restaurantPosIntegrations?restaurant_id=${restaurantId}`
  );
  return response.json();
};

function* fetchPOSIntegration(action) {
  try {
    let posIntegration = yield call(onPOSIntegrationRequest, action.payload);
    yield put(
      actions.fetchPOSIntegrationSuccess(
        action.payload,
        posIntegration?.pos_integrations
      )
    );
  } catch (error) {
    yield put(actions.fetchPOSIntegrationFailure(error));
  }
}

const onConnectStripeRequest = async (data) => {
  const response = await fetch(
    `https://orderbyte.io/api/subscription/v1/stripe/create-acc-link`,
    {
      headers: {
        'Content-Type': 'application/json',
        auth: 'order_xht4sAytlopr8hn_byte',
      },
      crossDomain: true,
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
    }
  );
  return response.json();
};

function* connectStripeRequest(action) {
  try {
    let stripeRes = yield call(onConnectStripeRequest, action.payload);
    if (stripeRes.status === 1) {
      const url = stripeRes.data.url;
      yield put(actions.connectStripeSuccess(url));
    }
  } catch (error) {
    yield put(actions.connectStripeFailure(error));
  }
}

const onConnectStripeRequestUpdate = async (data) => {
  const response = await fetch(
    `https://orderbyte.io/api/subscription/v1/stripe/create-acc-link-update`,
    {
      headers: {
        'Content-Type': 'application/json',
        auth: 'order_xht4sAytlopr8hn_byte',
      },
      crossDomain: true,
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
    }
  );
  return response.json();
};

function* connectStripeRequestUpdate(action) {
  try {
    let stripeRes = yield call(onConnectStripeRequestUpdate, action.payload);
    if (stripeRes.status === 1) {
      const url = stripeRes.data.url;
      yield put(actions.connectStripeSuccess(url));
    }
  } catch (error) {
    yield put(actions.connectStripeFailure(error));
  }
}

const onCreateChecklistStatus = async (data) => {
  let dataSent = {
    id_restaurant: data,
  };
  const response = await fetch(
    `https://orderbyte.io/api/subscription/v1/api/create-aggre`,
    {
      headers: {
        'Content-Type': 'application/json',
        auth: 'order_xht4sAytlopr8hn_byte',
      },
      crossDomain: true,
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(dataSent),
    }
  );
  return response.json();
};

function* createChecklistStatus(action) {
  try {
    let checklistStatusRes = yield call(
      onCreateChecklistStatus,
      action.payload
    );
    if (checklistStatusRes.msg === 'done') {
      let checklistData = checklistStatusRes.data;
      yield put(actions.createChecklistStatusSuccess(checklistData));
    }
  } catch (error) {
    yield put(actions.createChecklistStatusFailure(error));
  }
}

const onFetchChecklistStatus = async (data) => {
  let dataSent = {
    id_restaurant: data,
  };
  const response = await fetch(
    `https://orderbyte.io/api/subscription/v1/api/get-onboard-aggre`,
    {
      headers: {
        'Content-Type': 'application/json',
        auth: 'order_xht4sAytlopr8hn_byte',
      },
      crossDomain: true,
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(dataSent),
    }
  );
  return response.json();
};

function* fetchChecklistStatus(action) {
  try {
    let checklistStatusRes = yield call(onFetchChecklistStatus, action.payload);
    if (checklistStatusRes.status === 1) {
      let checklistData = checklistStatusRes.data;
      yield put(actions.fetchChecklistStatusSuccess(checklistData));
    }
  } catch (error) {
    yield put(actions.fetchChecklistStatusFailure(error));
  }
}

const onUpdateChecklistStatus = async (data) => {
  let dataSent = data;
  const response = await fetch(
    `https://orderbyte.io/api/subscription/v1/api/update-aggre-status`,
    {
      headers: {
        'Content-Type': 'application/json',
        auth: 'order_xht4sAytlopr8hn_byte',
      },
      crossDomain: true,
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(dataSent),
    }
  );
  return response.json();
};

function* updateChecklistStatus(action) {
  try {
    let checklistStatusRes = yield call(
      onUpdateChecklistStatus,
      action.payload
    );
    if (checklistStatusRes.msg === 'done') {
      let checklistData = checklistStatusRes;
      yield put(actions.updateChecklistStatusSuccess(checklistData));
    }
  } catch (error) {
    yield put(actions.updateChecklistStatusFailure(error));
  }
}

const onFetchSubscriptionPlan = async (brandId, restaurantId) => {
  const response = await fetch(
    `https://orderbyte.io/api/promo/v1/subscription/plan/${restaurantId}/${brandId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        auth: '7dja63nf0jfdvuHtfLTVBrwdakcitnesw1',
      },
      crossDomain: true,
      method: 'GET',
      mode: 'cors',
    }
  );
  return response.json();
};

function* fetchSubscriptionPlan({ payload }) {
  try {
    let subscriptionPlanRes = yield call(
      onFetchSubscriptionPlan,
      payload.brandId,
      payload.restaurantId
    );
    if (subscriptionPlanRes.status === 1) {
      let subscriptionPlanData = subscriptionPlanRes.data;
      yield put(actions.fetchSubscriptionPlanSuccess(subscriptionPlanData));
    }
  } catch (error) {
    yield put(actions.fetchSubscriptionPlanFailure(error));
  }
}

const onfetchReferralList = async () => {
  const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9';
  const response = await fetch(
    `https://orderbyte.io/api/refferal/v1/get_referral`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      crossDomain: true,
      method: 'GET',
      mode: 'cors',
    }
  );

  return response.json();
};

function* fetchReferralList() {
  try {
    let referralResponse = yield call(onfetchReferralList);
    if (referralResponse) {
      let referralResponseData = referralResponse;
      yield put(actions.fetchReferralListSuccess(referralResponseData));
    }
  } catch (error) {
    yield put(actions.fetchReferralListFailure(error));
  }
}

const onfetchReferralConditionList = async () => {
  const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9';
  const response = await fetch(
    `https://orderbyte.io/api/refferal/v1/referral_conditions`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      crossDomain: true,
      method: 'GET',
      mode: 'cors',
    }
  );

  return response.json();
};

function* fetchReferralConditionList() {
  try {
    let referralResponse = yield call(onfetchReferralConditionList);
    if (referralResponse) {
      let referralResponseData = referralResponse;
      yield put(
        actions.fetchReferralListConditionSuccess(referralResponseData)
      );
    }
  } catch (error) {
    yield put(actions.fetchReferralListConditionFailure(error));
  }
}

const onCreateReferral = async (data) => {
  let dataSent = {
    name: data.name,
  };
  // console.log('saga data sent', dataSent);

  const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9';
  try {
    const response = await fetch(
      `https://orderbyte.io/api/refferal/v1/create_referral`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataSent),
        redirect: 'follow',
        mode: 'cors',
        crossDomain: true,
      }
    );

    const json = await response.json(); // Wait for the response and parse it to JSON

    if (response.ok) {
      // Check if the request was successful (status code in the range 200-299)
      Notification('success', 'New referral created successfully!');
    } else {
      Notification('error', 'Referral creation failed.');
    }
    return json;
  } catch (err) {
    console.log('error-------', err);
    Notification(
      'error',
      'You got some error. Referral has not been completed'
    );
  }
};

function* createReferral(action) {
  try {
    let referralResponse = yield call(onCreateReferral, action.payload);

    if (referralResponse) {
      let referralResponseData = referralResponse;
      // yield put(actions.fetchReferralListSuccess(referralResponseData));
    }
  } catch (error) {
    // yield put(actions.fetchReferralListFailure(error));
  }
}

const onEditReferral = async (data) => {
  let dataSent = {
    name: data.name,
  };

  const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9';
  try {
    const response = await fetch(
      `https://orderbyte.io/api/refferal/v1/update/${data.id}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataSent),
        redirect: 'follow',
        mode: 'cors',
        crossDomain: true,
      }
    );

    const json = await response.json(); // Wait for the response and parse it to JSON

    if (response.ok) {
      // Check if the request was successful (status code in the range 200-299)
      Notification('success', 'Referral updated successfully!');
    } else {
      Notification('error', 'Referral updating is failed.');
    }
    return json;
  } catch (err) {
    console.log('error-------', err);
    Notification('error', 'You got some error.');
  }
};

function* editReferral(action) {
  try {
    let referralResponse = yield call(onEditReferral, action.payload);

    if (referralResponse) {
      let referralResponseData = referralResponse;
      // yield put(actions.fetchReferralListSuccess(referralResponseData));
    }
  } catch (error) {
    // yield put(actions.fetchReferralListFailure(error));
  }
}

const onDeleteReferral = async (data) => {
  const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9';
  try {
    const response = await fetch(
      `https://orderbyte.io/api/refferal/v1/delete/${data}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        mode: 'cors',
        crossDomain: true,
      }
    );

    const json = await response.json(); // Wait for the response and parse it to JSON

    if (response.ok) {
      // Check if the request was successful (status code in the range 200-299)
      Notification('success', 'Referral deleted successfully!');
    } else {
      Notification('error', 'Referral deleting is failed.');
    }
    return json;
  } catch (err) {
    console.log('error-------', err);
    Notification('error', 'You got some error.');
  }
};

function* deleteReferral(action) {
  try {
    let referralResponse = yield call(onDeleteReferral, action.payload);

    if (referralResponse) {
      let referralResponseData = referralResponse;
      // yield put(actions.fetchReferralListSuccess(referralResponseData));
    }
  } catch (error) {
    // yield put(actions.fetchReferralListFailure(error));
  }
}

const onCreateReferralRestaurant = async (data) => {
  // console.log('saga data sent', data);

  const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9';
  try {
    const response = await fetch(
      `https://orderbyte.io/api/refferal/v1/referral_condition`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        redirect: 'follow',
        mode: 'cors',
        crossDomain: true,
      }
    );

    const json = await response.json(); // Wait for the response and parse it to JSON

    if (response.ok) {
      // Check if the request was successful (status code in the range 200-299)
      Notification('success', 'New referral restaurant created successfully!');
    } else {
      Notification('error', 'Referral creation failed.');
    }
    return json;
  } catch (err) {
    console.log('error-------', err);
    Notification(
      'error',
      'You got some error. Referral has not been completed'
    );
  }
};

function* createReferralRestaurant(action) {
  try {
    let referralResponse = yield call(
      onCreateReferralRestaurant,
      action.payload
    );

    if (referralResponse) {
      let referralResponseData = referralResponse;
      yield put(actions.createReferralRestaurantSuccess(referralResponseData));
    }
  } catch (error) {
    yield put(actions.createReferralRestaurantFailure(error));
  }
}

const onEditReferralRestaurant = async (data) => {
  // console.log('saga data sent', data);

  const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9';
  try {
    const response = await fetch(
      `https://orderbyte.io/api/refferal/v1/referral_condition_update/${data.id}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        redirect: 'follow',
        mode: 'cors',
        crossDomain: true,
      }
    );

    const json = await response.json(); // Wait for the response and parse it to JSON

    if (response.ok) {
      // Check if the request was successful (status code in the range 200-299)
      Notification('success', 'Edit referral restaurant created successfully!');
    } else {
      Notification('error', 'Referral edition failed.');
    }
    return json;
  } catch (err) {
    console.log('error-------', err);
    Notification(
      'error',
      'You got some error. Referral has not been completed'
    );
  }
};

function* editReferralRestaurant(action) {
  try {
    let referralResponse = yield call(onEditReferralRestaurant, action.payload);

    if (referralResponse) {
      let referralResponseData = referralResponse;
      // yield put(actions.fetchReferralListSuccess(referralResponseData));
    }
  } catch (error) {
    // yield put(actions.fetchReferralListFailure(error));
  }
}

const onDeleteReferralRestaurant = async (data) => {
  const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9';
  try {
    const response = await fetch(
      `https://orderbyte.io/api/refferal/v1/referral_condition_delete/${data}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        mode: 'cors',
        crossDomain: true,
      }
    );

    const json = await response.json(); // Wait for the response and parse it to JSON

    if (response.ok) {
      // Check if the request was successful (status code in the range 200-299)
      Notification('success', 'Referral restaurant deleted successfully!');
    } else {
      Notification('error', 'Referral deleting is failed.');
    }
    return json;
  } catch (err) {
    console.log('error-------', err);
    Notification('error', 'You got some error.');
  }
};

function* deleteReferralRestaurant(action) {
  try {
    let referralResponse = yield call(
      onDeleteReferralRestaurant,
      action.payload
    );

    if (referralResponse) {
      yield put(actions.deleteReferralRestaurantSuccess(action.payload));
    }
  } catch (error) {
    yield put(actions.deleteReferralRestaurantFailure(error));
  }
}

const onCreateTelegramSetting = async (data) => {
  // console.log('saga data sent', data);

  try {
    const response = await fetch(
      `https://orderbyte.io/api/subscription/v1/api/add-telegram`,
      {
        method: 'POST',
        headers: {
          auth: 'order_xht4sAytlopr8hn_byte',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        redirect: 'follow',
        mode: 'cors',
        crossDomain: true,
      }
    );

    const json = await response.json(); // Wait for the response and parse it to JSON

    if (response.ok) {
      // Check if the request was successful (status code in the range 200-299)
      Notification('success', 'Telegram settings added successfully!');
    } else {
      Notification('error', 'Telegram settings creation failed.');
    }
    return json;
  } catch (err) {
    console.log('error-------', err);
    Notification(
      'error',
      'You got some error. Telegram settings has not been completed'
    );
  }
};

function* createTelegramSetting(action) {
  try {
    let telegramResponse = yield call(onCreateTelegramSetting, action.payload);

    if (telegramResponse.status === 1) {
      let telegramResponseData = telegramResponse;
      yield put(actions.createTelegramSettingSuccess(telegramResponseData));
    }
  } catch (error) {
    yield put(actions.createTelegramSettingFailure(error));
  }
}

const onCreatePaymentSetting = async (data) => {
  // console.log('saga data sent', data);

  try {
    const response = await fetch(
      `https://orderbyte.io/api/subscription/v1/api/add_payment_settings_info`,
      {
        method: 'POST',
        headers: {
          auth: 'order_xht4sAytlopr8hn_byte',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        redirect: 'follow',
        mode: 'cors',
        crossDomain: true,
      }
    );

    const json = await response.json(); // Wait for the response and parse it to JSON

    if (response.ok) {
      // Check if the request was successful (status code in the range 200-299)
      Notification('success', 'Payment settings added successfully!');
    } else {
      Notification('error', 'Payment settings creation failed.');
    }
    return json;
  } catch (err) {
    console.log('error-------', err);
    Notification(
      'error',
      'You got some error. Payment settings has not been completed'
    );
  }
};

function* createPaymentSetting(action) {
  try {
    let paymentResponse = yield call(onCreatePaymentSetting, action.payload);

    if (paymentResponse.status === 1) {
      let paymentResponseData = paymentResponse;
      yield put(actions.createPaymentSettingSuccess(paymentResponseData));
    }
  } catch (error) {
    yield put(actions.createPaymentSettingFailure(error));
  }
}

const onCreateMarketingMaterials = async (data) => {
  // console.log('saga data sent', data);

  try {
    const response = await fetch(
      `https://orderbyte.io/api/subscription/v1/api/markating-link/`,
      {
        method: 'POST',
        headers: {
          auth: 'order_xht4sAytlopr8hn_byte',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        redirect: 'follow',
        mode: 'cors',
        crossDomain: true,
      }
    );

    const json = await response.json(); // Wait for the response and parse it to JSON

    if (response.ok) {
      // Check if the request was successful (status code in the range 200-299)
      Notification('success', 'Market materials settings added successfully!');
    } else {
      Notification('error', 'Market materials settings creation failed.');
    }
    return json;
  } catch (err) {
    console.log('error-------', err);
    Notification(
      'error',
      'You got some error. Market materials settings has not been completed'
    );
  }
};

function* createMarketingMaterials(action) {
  try {
    let marketMaterialsResponse = yield call(
      onCreateMarketingMaterials,
      action.payload
    );

    if (marketMaterialsResponse.status === 1) {
      let marketMaterialsResponseData = marketMaterialsResponse;
      yield put(
        actions.createMarketingMaterialsSuccess(marketMaterialsResponseData)
      );
    }
  } catch (error) {
    yield put(actions.createMarketingMaterialsFailure(error));
  }
}

const onfetchSubscriptionStatus = async (id) => {
  const response = await fetch(
    `https://orderbyte.io/api/promo/v1/subscription/stat/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        auth: '7dja63nf0jfdvuHtfLTVBrwdakcitnesw1',
      },
      crossDomain: true,
      method: 'GET',
      mode: 'cors',
    }
  );
  return response.json();
};

function* fetchSubscriptionStatus(action) {
  try {
    let subscriptionStatusRes = yield call(
      onfetchSubscriptionStatus,
      action.payload
    );
    if (subscriptionStatusRes.status === 1) {
      let subscriptionData = subscriptionStatusRes.data;
      yield put(actions.fetchSubscriptionStatusSuccess(subscriptionData));
    }
  } catch (error) {
    yield put(actions.fetchSubscriptionStatusFailure(error));
  }
}

const onfetchGuardRail = async (id) => {
  const response = await fetch(
    `https://orderbyte.io/api/promo/v1/subscription/plan/timing/table/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        auth: '7dja63nf0jfdvuHtfLTVBrwdakcitnesw1',
      },
      crossDomain: true,
      method: 'GET',
      mode: 'cors',
    }
  );
  return response.json();
};

function* fetchGuardRail(action) {
  try {
    let guardRailStatusRes = yield call(onfetchGuardRail, action.payload);
    if (guardRailStatusRes.status === 1) {
      let subscriptionData = guardRailStatusRes.data;
      yield put(actions.fetchGuardRailStatusSuccess(subscriptionData));
    }
  } catch (error) {
    yield put(actions.fetchGuardRailStatusFailure(error));
  }
}

const onFetchCompanyInfo = async (id) => {
  const response = await fetch(
    `https://orderbyte.io/api/subscription/v1/api/company/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        auth: 'order_xht4sAytlopr8hn_byte',
      },
      crossDomain: true,
      method: 'GET',
      mode: 'cors',
    }
  );
  return response.json();
};

function* fetchCompanyInfo(action) {
  try {
    let companyInfoStatusRes = yield call(onFetchCompanyInfo, action.payload);

    if (companyInfoStatusRes.status === 1) {
      let companyInfoData = companyInfoStatusRes.companyInfo;

      yield put(actions.fetchCompanyInfoStatusSuccess(companyInfoData));
    }
  } catch (error) {
    yield put(actions.fetchCompanyInfoStatusFailure(error));
  }
}

const onFetchStripeSubscriptionInfo = async (id) => {
  const response = await fetch(
    `https://orderbyte.io/api/finencial/v1/get_subscription_model/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        // auth: 'order_xht4sAytlopr8hn_byte',
      },
      crossDomain: true,
      method: 'GET',
      mode: 'cors',
    }
  );
  return response.json();
};

function* fetchStripeSubscriptionInfo(action) {
  try {
    let stripeSubscriptionStatusRes = yield call(
      onFetchStripeSubscriptionInfo,
      action.payload
    );
    if (stripeSubscriptionStatusRes) {
      let stripeSubscriptionInfoData = stripeSubscriptionStatusRes;

      yield put(
        actions.fetchStripeSubscriptionStatusSuccess(
          stripeSubscriptionStatusRes
        )
      );
    }
  } catch (error) {
    yield put(actions.fetchStripeSubscriptionStatusFailure(error));
  }
}

const onFetchDateList = async (brandId, restaurantId) => {
  const response = await fetch(
    `https://orderbyte.io/api/subscription/purchased/dateslist/${restaurantId}/${brandId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        auth: 'order_xht4sAytlopr8hn_byte',
      },
      crossDomain: true,
      method: 'GET',
      mode: 'cors',
    }
  );
  return response.json();
};

function* fetchDateList({ payload }) {
  try {
    let dateListStatusRes = yield call(
      onFetchDateList,
      payload.brandId,
      payload.restaurantId
    );

    if (dateListStatusRes.status === 1) {
      let dateListData = dateListStatusRes.companyInfo;

      yield put(actions.fetchDateListStatusSuccess(dateListData));
    }
  } catch (error) {
    yield put(actions.fetchDateListStatusFailure(error));
  }
}

const onPrinterIntegrationRequest = async (restaurantId) => {
  const response = await fetch(
    `${siteConfig.apiUrl}/escPosData?restaurant_id=${restaurantId}`
  );
  return response.json();
};

function* fetchPrinterIntegration(action) {
  try {
    let posIntegration = yield call(
      onPrinterIntegrationRequest,
      action.payload
    );
    console.log({ posIntegration });
    yield put(
      actions.fetchPrinterIntegrationSuccess(action.payload, posIntegration)
    );
  } catch (error) {
    yield put(actions.fetchPrinterIntegrationFailure(error));
  }
}

export default function* accountSettingsSaga() {
  yield all([
    yield takeEvery(actions.FETCH_RKEEPER_SETTINGS_START, fetchRKeeperSettings),
    yield takeEvery(
      actions.CREATE_RKEEPER_SETTINGS_START,
      createRKeeperSettings
    ),
    yield takeEvery(
      actions.SUBMIT_RKEEPER_SETTINGS_START,
      submitRKeeperSettings
    ),
    yield takeEvery(actions.FETCH_PARTNER_SETTINGS_START, fetchPartnerSettings),
    yield takeEvery(
      actions.SUBMIT_PARTNER_SETTINGS_START,
      submitPartnerSettings
    ),
    yield takeEvery(
      actions.FETCH_AUTOMATION_SETTINGS_START,
      fetchAutomationSettings
    ),
    yield takeEvery(
      actions.SUBMIT_AUTOMATION_SETTINGS_START,
      submitAutomationSettings
    ),
    yield takeEvery(actions.SUBMIT_PARTNER_PLAN_START, submitPartnerPlan),
    yield takeEvery(
      actions.FETCH_BRAND_SETTINGS_START,
      fetchBrandSettingsStart
    ),
    yield takeEvery(actions.SUBMIT_CREATE_SITE_START, submitCreateSite),
    yield takeEvery(actions.SUBMIT_CREATE_MENU_START, submitCreateMenu),
    yield takeEvery(actions.SUBMIT_FILL_BRAND_START, submitFillBrand),
    yield takeEvery(actions.SUBMIT_SITE_ADDRESS_START, submitSiteAddress),
    yield takeEvery(
      actions.SUBMIT_SITE_ADDRESS_DOMAIN_START,
      submitSiteAddressDomain
    ),
    yield takeEvery(actions.FETCH_IIKO_SETTINGS_START, fetchIikoSettings),
    yield takeEvery(actions.CREATE_IIKO_SETTINGS_START, createIikoSettings),
    yield takeEvery(actions.SUBMIT_IIKO_SETTINGS_START, submitIikoSettings),
    yield takeEvery(actions.FETCH_POS_INTEGRATION_START, fetchPOSIntegration),
    yield takeEvery(
      actions.FETCH_PRINTER_INTEGRATION_START,
      fetchPrinterIntegration
    ),
    yield takeEvery(actions.CREATE_CHECKLIST_STATUS, createChecklistStatus),
    yield takeEvery(actions.FETCH_CHECKLIST_STATUS, fetchChecklistStatus),
    yield takeEvery(actions.CONNECT_STRIPE_REQUEST, connectStripeRequest),
    yield takeEvery(
      actions.CONNECT_STRIPE_REQUEST_UPDATE,
      connectStripeRequestUpdate
    ),
    yield takeEvery(actions.UPDATE_CHECKLIST_STATUS, updateChecklistStatus),
    yield takeEvery(actions.FETCH_SUBSCRIPTION_STATUS, fetchSubscriptionStatus),
    yield takeEvery(actions.FETCH_GUARD_RAIL, fetchGuardRail),
    yield takeEvery(actions.FETCH_COMPANY_INFO, fetchCompanyInfo),
    yield takeEvery(
      actions.FETCH_STRIPE_SUBSCRIPTION_INFO,
      fetchStripeSubscriptionInfo
    ),
    yield takeEvery(actions.FETCH_DATE_LIST, fetchDateList),
    yield takeEvery(actions.FETCH_REFERRAL_LIST, fetchReferralList),
    yield takeEvery(actions.CREATE_REFERRAL, createReferral),
    yield takeEvery(actions.EDIT_REFERRAL, editReferral),
    yield takeEvery(actions.DELETE_REFERRAL, deleteReferral),
    yield takeEvery(
      actions.FETCH_REFERRAL_CONDITION_LIST,
      fetchReferralConditionList
    ),
    yield takeEvery(
      actions.CREATE_REFERRAL_RESTAURANT,
      createReferralRestaurant
    ),
    yield takeEvery(actions.EDIT_REFERRAL_RESTAURANT, editReferralRestaurant),
    yield takeEvery(
      actions.DELETE_REFERRAL_RESTAURANT,
      deleteReferralRestaurant
    ),
    yield takeEvery(actions.CREATE_TELEGRAM_SETTING, createTelegramSetting),
    yield takeEvery(actions.CREATE_PAYMENT_SETTING, createPaymentSetting),
    yield takeEvery(
      actions.CREATE_MARKET_MATERIAL_SETTING,
      createMarketingMaterials
    ),
    yield takeEvery(actions.FETCH_SUBSCRIPTION_PLAN, fetchSubscriptionPlan),
  ]);
}
