import siteConfig from '@iso/config/site.config';
import { newTestOrder, newTestOrderBrand } from './config';
import Moment from 'moment';

let ws;

const actions = {
  REQUEST_POSTS_RESTAURANT_ORDERS: 'REQUEST_POSTS_RESTAURANT_ORDERS',
  RECEIVE_POSTS_RESTAURANT_ORDERS: 'RECEIVE_POSTS_RESTAURANT_ORDERS',
  REQUEST_SUBSCRIBE_RESTAURANT_ORDERS: 'REQUEST_SUBSCRIBE_RESTAURANT_ORDERS',
  RECEIVE_SUBSCRIBE_RESTAURANT_ORDERS: 'RECEIVE_SUBSCRIBE_RESTAURANT_ORDERS',
  RECEIVE_NOTIFICATION_RESTAURANT_ORDERS:
    'RECEIVE_NOTIFICATION_RESTAURANT_ORDERS',

  UPDATE_RESTAURANT_ORDERS: 'UPDATE_RESTAURANT_ORDERS',
  UPDATE_RESTAURANT_ORDERS_SAGA: 'UPDATE_RESTAURANT_ORDERS_SAGA',
  UPDATE_ORDER: 'UPDATE_ORDER',
  UPDATE_PROMO_STATUS: 'UPDATE_PROMO_STATUS',
  UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',
  DELIVERY_ORDER: 'DELIVERY_ORDER',
  CUSTOMER_CANCELLED_ORDER: 'CUSTOMER_CANCELLED_ORDER',
  RESTAURANT_CANCELLED_ORDER: 'RESTAURANT_CANCELLED_ORDER',
  SUBSCRIBE_RESTAURANT_ORDERS: 'SUBSCRIBE_RESTAURANT_ORDERS',
  LOGOUT: 'LOGOUT',

  CLEAR_RESTAURANT_ORDERS: 'CLEAR_RESTAURANT_ORDERS',
  CLOSE_WEB_SOCKET: 'CLEAR_RESTAURANT_ORDERS',

  /*Все активные заказы*/
  FETCH_GET_ACTIVE_ORDERS_START: 'FETCH_GET_ACTIVE_ORDERS_START',
  FETCH_GET_ACTIVE_ORDERS_SUCCESS: 'FETCH_GET_ACTIVE_ORDERS_SUCCESS',
  FETCH_GET_ACTIVE_ORDERS_FAILURE: 'FETCH_GET_ACTIVE_ORDERS_FAILURE',

  /*Все активные заказы*/
  FETCH_GET_WIDGETS_DATA_START: 'FETCH_GET_WIDGETS_DATA_START',
  FETCH_GET_WIDGETS_DATA_SUCCESS: 'FETCH_GET_WIDGETS_DATA_SUCCESS',
  FETCH_GET_WIDGETS_DATA_FAILURE: 'FETCH_GET_WIDGETS_DATA_FAILURE',

  REQUEST_POSTS_ORDER_DELIVERY_COST: 'REQUEST_POSTS_ORDER_DELIVERY_COST',
  RECEIVE_POSTS_ORDER_DELIVERY_COST: 'RECEIVE_POSTS_ORDER_DELIVERY_COST',
  SHOW_REFUND_MODAL: 'SHOW_REFUND_MODAL',
  HIDE_REFUND_MODAL: 'HIDE_REFUND_MODAL',
  SHOW_DISPUTE_MODAL: 'SHOW_DISPUTE_MODAL',
  HIDE_DISPUTE_MODAL: 'HIDE_DISPUTE_MODAL',
  SHOW_NEW_DISPUTE_MODAL: 'SHOW_NEW_DISPUTE_MODAL',
  HIDE_NEW_DISPUTE_MODAL: 'HIDE_NEW_DISPUTE_MODAL',
  SHOW_CONFIRM_REFUND_MODAL: 'SHOW_CONFIRM_REFUND_MODAL',
  HIDE_CONFIRM_REFUND_MODAL: 'HIDE_CONFIRM_REFUND_MODAL',
  SET_REFUNDABLE_ORDER: 'SET_REFUNDABLE_ORDER',
  SET_REFUNDABLE_DETAILS: 'SET_REFUNDABLE_DETAILS',
  SEND_REFUND_REQUEST__START: 'SEND_REFUND_REQUEST__START',
  SEND_REFUND_REQUEST__SUCCESS: 'SEND_REFUND_REQUEST__SUCCESS',
  ADD_REFUNDED_ID: 'ADD_REFUNDED_ID',
  SET_REFUNDED_REASON: 'SET_REFUNDED_REASON',
  SET_REFUNDED_PRICE: 'SET_REFUNDED_PRICE',
  CREATE_DISPUTE: 'CREATE_DISPUTE',
  CREATE_REPRINT: 'CREATE_REPRINT',
  CHANGE_DISPUTE_BUTTON_COLOR: 'CHANGE_DISPUTE_BUTTON_COLOR',
  SEND_DISPUTE_MESSAGE: 'SEND_DISPUTE_MESSAGE',
  FETCH_GET_ALL_DISPUTE: 'FETCH_GET_ALL_DISPUTE',
  FETCH_GET_ALL_DISPUTE_SUCCESS: 'FETCH_GET_ALL_DISPUTE_SUCCESS',
  UPDATE_DISPUTE: 'UPDATE_DISPUTE',
  FETCH_DISPUTE_HISTORY: 'FETCH_DISPUTE_HISTORY',
  FETCH_DISPUTE_HISTORY_SUCCESS: 'FETCH_DISPUTE_HISTORY_SUCCESS',
  FETCH_GET_ALL_BLOG: 'FETCH_GET_ALL_BLOG',
  FETCH_GET_ALL_BLOG_SUCCESS: 'FETCH_GET_ALL_BLOG_SUCCESS',
  CREATE_BLOG: 'CREATE_BLOG',
  EDIT_BLOG: 'EDIT_BLOG',
  DELETE_BLOG: 'DELETE_BLOG',
  FETCH_GET_ALL_NEWS: 'FETCH_GET_ALL_NEWS',
  FETCH_GET_ALL_NEWS_SUCCESS: 'FETCH_GET_ALL_NEWS_SUCCESS',
  CREATE_NEWS: 'CREATE_NEWS',
  EDIT_NEWS: 'EDIT_NEWS',
  DELETE_NEWS: 'DELETE_NEWS',
  FETCH_GET_ALL_CRM: 'FETCH_GET_ALL_CRM',
  FETCH_CRM_FILTER_START: 'FETCH_CRM_FILTER_START',
  FETCH_GET_ALL_CRM_SUCCESS: 'FETCH_GET_ALL_CRM_SUCCESS',
  ADD_SEND_SMS: 'ADD_SEND_SMS',
  ADD_SEND_EMAIL: 'ADD_SEND_EMAIL',
  ADD_SEND_PUSH: 'ADD_SEND_PUSH',

  FETCH_GET_ALL_PROMO_DISCOUNT: 'FETCH_GET_ALL_PROMO_DISCOUNT',
  FETCH_GET_ALL_PROMO_DISCOUNT_SUCCESS: 'FETCH_GET_ALL_PROMO_DISCOUNT_SUCCESS',
  CREATE_PROMO_DISCOUNT: 'CREATE_PROMO_DISCOUNT',
  EDIT_PROMO_DISCOUNT: 'EDIT_PROMO_DISCOUNT',
  DELETE_PROMO_DISCOUNT: 'DELETE_PROMO_DISCOUNT',

  sendDisputeMessage: (chatId, message) => ({
    type: actions.SEND_DISPUTE_MESSAGE,
    payload: { chatId, message },
  }),
  showDisputeModal: () => ({
    type: actions.SHOW_DISPUTE_MODAL,
  }),
  hideDisputeModal: () => ({
    type: actions.HIDE_DISPUTE_MODAL,
  }),
  showNewDisputeModal: () => ({
    type: actions.SHOW_NEW_DISPUTE_MODAL,
  }),
  hideNewDisputeModal: () => ({
    type: actions.HIDE_NEW_DISPUTE_MODAL,
  }),
  createDispute: (dispute) => ({
    type: actions.CREATE_DISPUTE,
    payload: dispute,
  }),
  createReprint: (orderId) => ({
    type: actions.CREATE_REPRINT,
    payload: orderId,
  }),
  fetchGetAllCRM: (requestData) => ({
    type: actions.FETCH_GET_ALL_CRM,
    payload: requestData,
  }),
  fetchCrmFiltersStart: (requestData) => ({
    type: actions.FETCH_CRM_FILTER_START,
    payload: requestData,
  }),
  addSendSms: (smsData) => ({
    type: actions.ADD_SEND_SMS,
    payload: smsData,
  }),
  addSendEmail: (emailData) => ({
    type: actions.ADD_SEND_EMAIL,
    payload: emailData,
  }),
  addSendPush: (pushData) => ({
    type: actions.ADD_SEND_PUSH,
    payload: pushData,
  }),
  fetchGetAllCrmSuccess: (allCRM) => ({
    type: actions.FETCH_GET_ALL_CRM_SUCCESS,
    payload: allCRM,
  }),
  changeDisputeButtonColor: () => ({
    type: actions.CHANGE_DISPUTE_BUTTON_COLOR,
  }),
  fetchGetAllDispute: () => ({
    type: actions.FETCH_GET_ALL_DISPUTE,
  }),
  fetchDisputeHistory: (disputeId) => ({
    type: actions.FETCH_DISPUTE_HISTORY,
    payload: disputeId,
  }),
  fetchDisputeHistorySuccess: (disputeHistory) => ({
    type: actions.FETCH_DISPUTE_HISTORY_SUCCESS,
    payload: disputeHistory,
  }),
  fetchGetAllDisputeSuccess: (allDispute) => ({
    type: actions.FETCH_GET_ALL_DISPUTE_SUCCESS,
    payload: allDispute,
  }),
  updateDispute: (updatedDispute) => ({
    type: actions.UPDATE_DISPUTE,
    payload: updatedDispute,
  }),
  fetchAllBlog: () => ({
    type: actions.FETCH_GET_ALL_BLOG,
  }),
  fetchGetAllBlogSuccess: (allBlog) => ({
    type: actions.FETCH_GET_ALL_BLOG_SUCCESS,
    payload: allBlog,
  }),
  createBlog: (data) => ({
    type: actions.CREATE_BLOG,
    payload: data,
  }),
  editBlog: (data) => ({
    type: actions.EDIT_BLOG,
    payload: data,
  }),
  deleteBlog: (blogId) => ({
    type: actions.DELETE_BLOG,
    payload: blogId,
  }),
  fetchAllNews: () => ({
    type: actions.FETCH_GET_ALL_NEWS,
  }),
  fetchGetAllNewsSuccess: (allNews) => ({
    type: actions.FETCH_GET_ALL_NEWS_SUCCESS,
    payload: allNews,
  }),
  createNews: (data) => ({
    type: actions.CREATE_NEWS,
    payload: data,
  }),
  editNews: (data) => ({
    type: actions.EDIT_NEWS,
    payload: data,
  }),
  deleteNews: (newsId) => ({
    type: actions.DELETE_NEWS,
    payload: newsId,
  }),
  fetchAllPromoDiscount: (data) => ({
    type: actions.FETCH_GET_ALL_PROMO_DISCOUNT,
    payload: data,
  }),
  fetchGetAllPromoDiscountSuccess: (allNews) => ({
    type: actions.FETCH_GET_ALL_PROMO_DISCOUNT_SUCCESS,
    payload: allNews,
  }),
  createPromoDiscount: (data) => ({
    type: actions.CREATE_PROMO_DISCOUNT,
    payload: data,
  }),
  editPromoDiscount: (data) => ({
    type: actions.EDIT_PROMO_DISCOUNT,
    payload: data,
  }),
  deletePromoDiscount: (data) => ({
    type: actions.DELETE_PROMO_DISCOUNT,
    payload: data,
  }),
  showRefundModal: () => ({
    type: actions.SHOW_REFUND_MODAL,
  }),
  hideRefundModal: () => ({
    type: actions.HIDE_REFUND_MODAL,
  }),
  showConfirmRefundModal: () => ({
    type: actions.SHOW_CONFIRM_REFUND_MODAL,
  }),
  hideConfirmRefundModal: () => ({
    type: actions.HIDE_CONFIRM_REFUND_MODAL,
  }),
  setRefundableOrder: (id, price) => ({
    type: actions.SET_REFUNDABLE_ORDER,
    id,
    price,
  }),
  setRefundableDetails: (reason, comment) => ({
    type: actions.SET_REFUNDABLE_DETAILS,
    payload: { reason, comment },
  }),
  sendRefundRequestStart: (data) => {
    return {
      type: actions.SEND_REFUND_REQUEST__START,
      payload: { data },
    };
  },
  sendRefundRequestSuccess: (data) => ({
    type: actions.SEND_REFUND_REQUEST__SUCCESS,
    payload: { data },
  }),
  addRefundedId: (id) => ({
    type: actions.ADD_REFUNDED_ID,
    payload: {
      id,
    },
  }),
  setRefundedReason: (reason) => ({
    type: actions.SET_REFUNDED_REASON,
    payload: {
      reason,
    },
  }),
  setRefundedPrice: (price) => ({
    type: actions.SET_REFUNDED_PRICE,
    payload: {
      price,
    },
  }),
  fetchGetActiveOrdersStart: () => ({
    type: actions.FETCH_GET_ACTIVE_ORDERS_START,
  }),
  fetchGetActiveOrdersSuccess: (activeOrders) => ({
    type: actions.FETCH_GET_ACTIVE_ORDERS_SUCCESS,
    payload: activeOrders,
  }),
  fetchGetActiveOrdersFailure: (error) => ({
    type: actions.FETCH_GET_ACTIVE_ORDERS_FAILURE,
    payload: error,
  }),

  fetchGetWidgetsDataStart: (restaurantId) => ({
    type: actions.FETCH_GET_WIDGETS_DATA_START,
    payload: restaurantId,
  }),
  fetchGetWidgetsDataSuccess: (widgetsData) => ({
    type: actions.FETCH_GET_WIDGETS_DATA_SUCCESS,
    payload: widgetsData,
  }),
  fetchGetWidgetsDataFailure: (error) => ({
    type: actions.FETCH_GET_WIDGETS_DATA_FAILURE,
    payload: error,
  }),

  getRestaurantOrders: (restaurantId) => {
    return (dispatch) => {
      const url =
        localStorage.getItem('userRole') === 'admin'
          ? `${siteConfig.apiUrl}/allorders`
          : `${siteConfig.apiUrl}/allorders/${restaurantId}`;

      dispatch({
        type: actions.REQUEST_POSTS_RESTAURANT_ORDERS,
      });
      return fetch(url)
        .then((response) => response.json())
        .then((json) => {
          if (json['orders'].length !== 0) {
            dispatch({
              type: actions.RECEIVE_POSTS_RESTAURANT_ORDERS,
              restaurantOrders: json['orders'],
            });
          }
        });
    };
  },
  getOrderDeliveryCost: (orderId) => {
    return (dispatch) => {
      const url = `${siteConfig.apiUrl}/deliverycost/${orderId}`;
      dispatch({
        type: actions.REQUEST_POSTS_ORDER_DELIVERY_COST,
      });
      return fetch(url)
        .then((response) => response.json())
        .then((json) => {
          /*          if (json['cost']) {
            /!*console.log(json);
            dispatch({
              type: actions.RECEIVE_POSTS_ORDER_DELIVERY_COST,
              cost: json['cost'],
            });*!/
            return json['cost'];
          }*/
          return json;
        });
    };
  },
  applyDeliveryOrder: (orderId, deliveryTypeTemp, status) => {
    return (dispatch) => {
      return fetch(
        `${siteConfig.apiUrl}/applydelivery/${orderId}?aggregator_id=${deliveryTypeTemp}&status=${status}`,
        {
          crossDomain: true,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
        }
      ).then(() => {});
    };
  },

  subscribeRestaurantOrders: () => {
    if (
      localStorage.getItem('id_token') &&
      localStorage.getItem('restaurantId') !== null
    ) {
      return (dispatch) => {
        function connect(isReconnect = false) {
          const wsUrl =
            localStorage.getItem('userRole') === 'admin'
              ? `${siteConfig.apiWSUrl}/subscribeOrders`
              : localStorage.getItem('userRole') === 'marketplace'
              ? `${
                  siteConfig.apiWSUrl
                }/subscribeMarketplaceOrders/${localStorage.getItem(
                  'restaurantId'
                )}`
              : localStorage.getItem('userRole') === 'network_admin'
              ? `${
                  siteConfig.apiWSUrl
                }/subscribeMarketplaceOrders/${localStorage.getItem(
                  'restaurantId'
                )}`
              : `${siteConfig.apiWSUrl}/subscribeOrders/${localStorage.getItem(
                  'restaurantId'
                )}`;
          document.cookie =
            'X-Authorization=' + localStorage.getItem('id_token') + '; path=/';

          ws?.close(1000, '');

          ws = new WebSocket(wsUrl);
          ws.onclose = function (e) {
            if (
              localStorage.getItem('restaurantId') &&
              localStorage.getItem('restaurantId') !== 0
            ) {
              console.log(
                Moment(new Date()).format('HH:mm:ss'),
                ': Socket is closed. Reconnect will be attempted in 5 second.',
                e
              );
              /*dispatch({
                type: actions.CLEAR_RESTAURANT_ORDERS,
              });*/
              setTimeout(function () {
                connect(true);
              }, 5000);
            } else {
              console.log(
                'Socket will be closed. Restaurant id = 0, reconnection disabled.',
                e
              );
            }
          };

          ws.onerror = function (err) {
            console.error('Socket encountered error: ', err, 'Closing socket');
            ws.close();
          };

          /*          dispatch({
            type: actions.SUBSCRIBE_RESTAURANT_ORDERS,
          });*/

          ws.addEventListener('message', (e) => {
            const js = JSON.parse(e.data);

            console.log(
              'WS order',
              js,
              isReconnect,
              !js.isFirstLoad,
              !isReconnect || !js.isFirstLoad
            );
            if (!isReconnect || !js.isFirstLoad) {
              if (
                typeof js.customer !== 'undefined' &&
                js.status !== 'delivered' &&
                js.status !== 'restaurant_cancelled' &&
                js.status !== 'customer_cancelled'
              ) {
                dispatch({
                  type: actions.RECEIVE_SUBSCRIBE_RESTAURANT_ORDERS,
                  restaurantOrders: js,
                });
              } else if (js.status === 'delivered') {
                dispatch({
                  type: actions.DELIVERY_ORDER,
                  restaurantOrders: js,
                });
              } else if (js.status === 'customer_cancelled') {
                dispatch({
                  type: actions.CUSTOMER_CANCELLED_ORDER,
                  restaurantOrders: js,
                });
              } else if (js.status === 'restaurant_cancelled') {
                dispatch({
                  type: actions.RESTAURANT_CANCELLED_ORDER,
                  restaurantOrders: js,
                });
              } else if (
                typeof js.type !== 'undefined' &&
                typeof js.orderId !== 'undefined' &&
                typeof js.id !== 'undefined'
              ) {
                dispatch({
                  type: actions.RECEIVE_NOTIFICATION_RESTAURANT_ORDERS,
                  notificationOrder: js,
                  orderId: js.orderId,
                });
              }
            }
            // else {
            //   dispatch({
            //     type: actions.RECEIVE_SUBSCRIBE_RESTAURANT_ORDERS,
            //     restaurantOrders: js,
            //   });
            // }
          });
          const int = setInterval(() => {
            if (ws.readyState === 1) {
              ws?.send('ready');
            } else {
              clearInterval(int);
            }
          }, 10 * 1000);

          return ws;
        }

        connect();
      };
    }
  },
  setOrderStatus: (order, status) => {
    order.status = status;
    console.log('status-----------------', status);

    return (dispatch) => {
      return fetch(
        `${siteConfig.apiUrl}/${order.brandId}/updateOrder/${order.restaurantId}/${order.orderId}`,
        {
          crossDomain: true,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          body: JSON.stringify(order),
        }
      ).then(() => {
        dispatch({
          type: actions.UPDATE_ORDER,
          status: order.status,
          originalOrderId: order.originalOrderId,
          id: order.orderId,
        });
      });
    };
  },
  setOrderPromoStatus: (scanData) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_PROMO_STATUS,
        promoName: scanData,
      });
    };
  },
  sendTestOrder: () => {
    const order = newTestOrder;
    const randomNumber = Math.floor(Math.random() * newTestOrderBrand.length);
    order.originalOrderId =
      Math.floor(Math.random() * 100) +
      '-' +
      Math.floor(Math.random() * 10000) +
      '-' +
      Math.floor(Math.random() * 10000);
    order.createdAt = Moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');
    return (dispatch) => {
      return fetch(
        `${siteConfig.apiUrl}/${
          newTestOrderBrand[randomNumber]
        }/orders/${localStorage.getItem('restaurantId')}`,
        {
          crossDomain: true,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          body: JSON.stringify(order),
        }
      ).then(() => {});
    };
  },
  updateOrder: (order) => {
    return (dispatch) => {};
  },
  closeWsOrder: () => {
    ws?.close();
    console.log('WebSocket close:', ws);
  },
};
export default actions;
